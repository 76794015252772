<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar"
            nzCollapsible
            nzWidth="256px"
            nzBreakpoint="md"
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null">
    <div class="sidebar-logo">
      <a href="https://ng.ant.design/" target="_blank">
        <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo">
        <h1>Ant Design Of Angular</h1>
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
   <!--   <li nz-submenu nzOpen nzTitle="开米" nzIcon="dashboard">-->
   <!--     <ul>-->
   <!--       <li nz-menu-item nzMatchRouter>-->
   <!--         <a routerLink="/welcome">欢迎页</a>-->
   <!--       </li>-->
   <!--       <li nz-menu-item nzMatchRouter>-->
   <!--         <a routerLink="/income">每日米况</a>-->
			<!--</li>-->
   <!--     </ul>-->
   <!--   </li>-->
			<li nz-submenu nzOpen nzTitle="产品管理" nzIcon="form">
			  <ul>
			    <li nz-menu-item nzMatchRouter>
			      <a routerLink="/product">产品</a>
				</li>
			    <li nz-menu-item nzMatchRouter>
			      <a routerLink="/producttype">产品类别</a>
			    </li>
			  </ul>
			</li>
      <li nz-submenu nzOpen nzTitle="新闻管理" nzIcon="form">
			  <ul>
			    <li nz-menu-item nzMatchRouter>
			      <a routerLink="/news">新闻列表</a>
				</li>
			  </ul>
			</li>
      <li nz-submenu nzOpen nzTitle="图片管理" nzIcon="form">
			  <ul>
			    <li nz-menu-item nzMatchRouter>
			      <a routerLink="/testimonials">Testimonials管理</a>
				  </li>
        <li nz-menu-item nzMatchRouter>
          <a routerLink="/capbilities">Capbilities管理</a>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a routerLink="/banner">Banner管理</a>
        </li>
			  </ul>
			</li>
      <li nz-submenu nzOpen nzTitle="用户联系" nzIcon="form">
			  <ul>
			    <li nz-menu-item nzMatchRouter>
			      <a routerLink="/contact">用户联系</a>
				  </li>
			  </ul>
			</li>
       <!--<li nz-submenu nzOpen nzTitle="会员管理" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/user">会员信息</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="车辆管理" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/car">车辆管理</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="商品管理" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/goods">商品列表</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="统计报表" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/sylistbase">用户资产变动记录</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/consumption">消费记录</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/reimbursement">报账记录</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="管理员管理" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/adminuser">管理员列表</a>
          </li>
        </ul>
      </li> -->
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i class="trigger"
               nz-icon
               [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            ></i>
        </span>
        <span *ngIf="isActive" class="exit-button">
          <button
            nz-popconfirm
            nzPopconfirmTitle="确认退出吗?"
            (nzOnConfirm)="confirm()"
            (nzOnCancel)="cancel()"
            nzPopconfirmPlacement="bottom"
            nz-button
          >
          {{UserInfo.mobile}}
        </button>
      </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>


